import { navigate } from "gatsby"
import React, { useRef } from "react"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../../components/layout"
import Seo from "../../../components/seo"
import ContactModal from "../../../components/contactModal"
import "./style.mgg.scss"

import ProductFunction1 from "/static/products/magaogao/产品能力_1.png"
import ProductFunction2 from "/static/products/magaogao/产品能力_2.png"
import ProductFunction3 from "/static/products/magaogao/产品能力_3.png"
import ProductFunction4 from "/static/products/magaogao/产品能力_4.png"
import CoreAblity from "/static/products/magaogao/核心优势.png"
import Services1 from "/static/products/magaogao/服务方式_1.png"
import Services2 from "/static/products/magaogao/服务方式_2.png"
import Services3 from "/static/products/magaogao/服务方式_3.png"
import Case1 from "/static/products/magaogao/成功案例.png"

const Mgg = () => {
  const modalRef = useRef()
  return (
    <Layout>
      <ContactModal ref={modalRef} />
      <Seo
        title="码垛规划_码垛规划软件_混码机器人算法-蓝胖智汇码高高"
        keywords="码垛规划,码垛规划软件,混码机器人算法"
        description="蓝胖智汇专注自研码垛规划、码垛规划软件、混码机器人算法，为客户提供仓储物流场景的多项智化升级服务，帮助客户提高供应链决策效率，降低企业管理成本。"
      />
      <div className="relative">
        <StaticImage
          src="../../../images/products-banner.png"
          alt="码垛规划_码垛规划软件_混码机器人算法-蓝胖智汇码高高"
          className="w-full"
        />
        <div className="absolute top-28 left-24 text-white text-3xl">
          产品列表
        </div>
      </div>
      <div className="zmm-product">
        <div id="zmm-v2-leading-image">
          <h1 className="title">码高高-智能混码计算平台</h1>
          <div className="description">
            智能求解最佳码垛规划方案，快速赋能码垛机器人处理在线/离线混码难题
          </div>
          <button
            type="button"
            className="contact-us-now"
            gtm-id="mgg-view-now"
            onClick={() => modalRef.current.openModal()}
          >
            立即了解
          </button>
        </div>
        <div id="mgg-container">
          <div className="title">产品能力</div>
          <div className="card-wrapper">
            <div className="left"></div>
            <div className="card tabs">
              <input
                id="tab-1"
                type="radio"
                className="tab tab-selector"
                defaultChecked={true}
                name="tab"
              />
              <label
                htmlFor="tab-1"
                className="tab tab-primary first-tab"
                gtm-id="mgg-sldie-products"
              >
                通用灵活
              </label>
              <input
                id="tab-2"
                type="radio"
                className="tab tab-selector"
                name="tab"
              />
              <label
                htmlFor="tab-2"
                className="tab tab-primary"
                gtm-id="mgg-sldie-products"
              >
                实时智能
              </label>
              <input
                id="tab-3"
                type="radio"
                className="tab tab-selector"
                name="tab"
              />
              <label
                htmlFor="tab-3"
                className="tab tab-primary"
                gtm-id="mgg-sldie-products"
              >
                规则丰富
              </label>
              <input
                id="tab-4"
                type="radio"
                className="tab tab-selector"
                name="tab"
              />
              <label
                htmlFor="tab-4"
                className="tab tab-primary last-tab"
                gtm-id="mgg-sldie-products"
              >
                集成交付
              </label>

              <div className="glider"></div>
              <section className="content">
                <div className="item" id="content-1">
                  <img
                    className="single-img"
                    src={ProductFunction1}
                    alt="在线实时混码"
                  />
                </div>
                <div className="item" id="content-2">
                  <img
                    className="single-img"
                    src={ProductFunction2}
                    alt="随机货物来序"
                  />
                </div>
                <div className="item" id="content-3">
                  <img
                    className="single-img"
                    src={ProductFunction3}
                    alt="码垛装载规则"
                  />
                </div>
                <div className="item" id="content-4">
                  <img
                    className="single-img"
                    src={ProductFunction4}
                    alt="蓝胖子码垛机器人"
                  />
                </div>
              </section>
            </div>
            <div className="right"></div>
          </div>
        </div>
        <div id="zmm-v2-scenario">
          <div className="title">典型场景</div>
          <div className="content">
            <div className="card card1">
              <div className="title">快递物流</div>
              <div className="desc">
                有效处理快递包裹场内转运临时码垛和离场分拣码垛需求，提高周转效率和托盘利用率
              </div>
            </div>
            <div className="card card2">
              <div className="title">零售配送</div>
              <div className="desc">
                根据配送订单临时拣选码垛，搭配波次划分策略，有效提升自动仓出库效率
              </div>
            </div>
            <div className="card card3">
              <div className="title">成品入库</div>
              <div className="desc">
                衔接上游装配产线，完成成品码垛入库，支持多SKU共用一条输运线，提高入库效率和托盘利用率
              </div>
            </div>
            <div className="card card4">
              <div className="title">食品冷链</div>
              <div className="desc">
                搭配蓝胖子码垛机器人工作站，有效处理食品、药品冷链场景的出入库无人码垛
              </div>
            </div>
          </div>
        </div>
        <div id="zmm-v2-strength">
          <div className="title">核心优势</div>
          <div className="content">
            <div className="left">
              <div className="strength">
                <div className="title">强大的算法开发能力</div>
                <div className="desc">
                  基于自研优化算法引擎“蓝胖智汇
                  Doraopt”开发，拥有全球领先的智能算法架构，有效结合AI数据驱动技术与运筹优化能力，求解复杂优化问题
                </div>
              </div>
              <div className="strength">
                <div className="title">专业的方案咨询实施</div>
                <div className="desc">
                  深耕订单交付与离场物流环节多年，涉及售前估算、打包设计、入仓管理、配载运输优化等，实现
                  销售-生产-运输 的全链路整合优化
                </div>
              </div>
              <div className="strength">
                <div className="title">完善的IT服务支持</div>
                <div className="desc">
                  通过ISO27001信息安全认证，服务过多家世界500强和跨国集团公司，提供算法SKD、API集成、云计算和定制系统开发全栈式技术服务
                </div>
              </div>
              <div className="strength">
                <div className="title">丰富的码垛机器人实施经验</div>
                <div className="desc">
                  依托蓝胖子码垛机器人多年实施经验，有效处理各类现实码垛场景需求，可提供整体方案设计与综合实施交付
                </div>
              </div>
            </div>
            <img className="right" src={CoreAblity} alt="核心优势" />
          </div>
        </div>

        <div id="zmm-v2-services">
          <div className="title">服务方式</div>
          <div className="content">
            <div className="cards">
              <div className="card">
                <img src={Services1} alt="服务方式-1" />
                <div className="text">
                  <div className="about">API接口调用服务</div>
                  <div className="desc">提供配套部署解决方案</div>
                </div>
              </div>
              <div className="card">
                <img src={Services2} alt="服务方式-2" />
                <div className="text">
                  <div className="about">业务系统集成</div>
                  <div className="desc">通过WMS、WCS、ERP直接下发码垛计划</div>
                </div>
              </div>
              <div className="card">
                <img src={Services3} alt="服务方式-3" />
                <div className="text">
                  <div className="about">综合咨询实施</div>
                  <div className="desc">多种特殊需求定制化交付实施</div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div id="zmm-v2-cases">
          <div className="title">成功案例</div>
          <div className="content">
            <div className="case-wrapper">
              <img src={Case1} className="img1" alt="长青集团码垛机器人" />
              <div className="desc">
                <div className="case-title">制造业</div>
                <div className="case-desc">
                  <p>
                    蓝胖子为长青集团提供以混码算法为核心的自动化输送分流码垛系统，助力长青集团实现数字化、智能化转型。
                  </p>
                  <div className="row">
                    <div className="col">
                      <div className="first-line">
                        90<span>%</span>
                      </div>
                      <div className="second-line">运输时间降低</div>
                    </div>
                    <div className="col">
                      <div className="first-line">
                        80<span>%</span>
                      </div>
                      <div className="second-line">人工劳动降低</div>
                    </div>
                    <div className="col">
                      <div className="first-line">
                        90<span>%</span>
                      </div>
                      <div className="second-line">人力成本使用效率提高</div>
                    </div>
                  </div>
                </div>
                <div className="check check1">
                  查看详情 <span> {">>"} </span>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div id="misc">
          <div className="content">
            <div className="qa">
              <div className="title">其他问题</div>
              <div className="detail">
                告诉我们您需要解决什么问题，专家会帮助您找到最适合您的解决方案
              </div>
              <button
                onClick={() => modalRef.current.openModal()}
                className="contact-us-now"
                gtm-id="mgg-contact-us"
              >
                联系我们
              </button>
            </div>
            <div className="try">
              <div className="product">
                <div className="about">开始体验 码高高</div>
                <div
                  className="go contact-us-now"
                  onClick={() => window.open("https://zmm.doraopt.com/")}
                >
                  免费试用
                </div>
              </div>
              <div className="product">
                <div className="about">更多产品</div>
                <div
                  className="go check-products"
                  onClick={() => navigate("/products")}
                >
                  查看更多产品
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Mgg
